import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import useEnglish from "../library/useEnglish"
import TextPage from "../pagesLayouts/TextPage"
import { WordpressPageQuery } from "../types/schema"

export default function ContactEn() {
  useEnglish()

  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "en" } }, slug: { eq: "contact-en" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return <TextPage data={data} language="en" />
}
